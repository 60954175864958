// Libraries
import axios from 'axios';
import axiosRetry from 'axios-retry';

// Utils
import { logError } from 'common/utils/errors';

axiosRetry(axios, {
  retries: 4,
  retryDelay: axiosRetry.exponentialDelay,
});

async function request(endpoint, method, data, headers = {}) {
  try {
    const url = window.gon.responseServiceApiUrl + endpoint;
    const response = await axios.request({ url, method, headers, data });

    if (response.status === 204) {
      return '';
    }
    return response.data;
  } catch (error) {
    let context;
    if (error.response) {
      context = { response: error.response };
    } else if (error.request) {
      context = { request: error.request };
    } else {
      context = { message: error.message };
    }

    logError(error, {
      context,
    });
    throw error;
  }
}

export function getRequest(endpoint) {
  return request(endpoint, 'GET');
}

export function postRequest(endpoint, data) {
  return request(endpoint, 'POST', data);
}

export function patchRequest(endpoint, data) {
  return request(endpoint, 'PATCH', data);
}

export function putRequest(endpoint, data) {
  return request(endpoint, 'PUT', data);
}
